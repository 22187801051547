import * as _ from 'lodash';
import {
  IRiskAssessment,
  IRiskAssessmentEditVm,
  ICreateRiskAssessmentResult,
  ICreateRiskAssessmentCommand,
  IRiskAssessmentListViewModel,
  createRiskAssessmentListFilter,
  IRiskAssessmentStatusMonitorFilterConfig,
  IRiskAssessmentStatusMonitorViewModel,
  createRiskAssessmentStatusMonitorFilter as createRiskAssessmentStatusMonitorSearchConfig,
  INewRiskassessmentVm,
  IRiskAssessmentHistoryCollection,
  IRiskAssessmentListSearchConfig,
  createRiskAssessmentListSearchConfig,
  IRiskAssessmentListViewModels,
  IRiskAssessmentStatusMonitorEntry,
} from '../../models/riskAssessment';
import httpClient from './httpClient';
import Routes from '../Routes';
import { RiskAssessmentUpdates } from '../../models/riskAssessment/RiskAssessmentUpdates';
import { IEnsureRiskAssessmentDocument, IUploadRiskAssessmentDocuments } from '../../models/riskAssessment/IEnsureRiskAssessmentDocument';
import { ILanguage } from '../../models/LanguageSettings';

interface IRiskAssessmentListResponse {
  riskAssessments: IRiskAssessmentListViewModel[];
  totalCount: number;
}

interface IRiskAssessmentStatusMonitorResponse {
  reportEntries: IRiskAssessmentStatusMonitorEntry[];
  totalCount: number;
}

const loadRiskAssessmentForEdit = async (tokenOrId: string): Promise<IRiskAssessmentEditVm> => {
  try {
    const response = await httpClient.getInstance().get<IRiskAssessmentEditVm>(`/riskassessments/${tokenOrId}`);
    return response.data;
  } catch (e) {
    console.error('loadRiskAssessmentForEdit', e);
    return null;
  }
};

const getRiskAssessmentList = async (config: IRiskAssessmentListSearchConfig): Promise<IRiskAssessmentListViewModels> => {
  const searchConfig = config ?? createRiskAssessmentListSearchConfig();
  const result = await httpClient.getInstance().post<IRiskAssessmentListResponse>(`riskAssessments/list`, searchConfig);
  return result.data;
};

const getRiskAssessmentStatusMonitor = async (config: IRiskAssessmentListSearchConfig): Promise<IRiskAssessmentStatusMonitorViewModel> => {
  const searchConfig = config ?? createRiskAssessmentStatusMonitorSearchConfig();
  const result = await httpClient.getInstance().post<IRiskAssessmentStatusMonitorResponse>(`riskAssessments/status`, searchConfig);
  return result.data;
  //return { reportEntries: result.data.report, totalCount: result.data.totalCount } as IRiskAssessmentStatusMonitorViewModel;
};

const createNewRiskAssessment = async (item: ICreateRiskAssessmentCommand): Promise<ICreateRiskAssessmentResult> => {
  const response = await httpClient.getInstance().post<ICreateRiskAssessmentResult>(Routes.NewRiskAssessment.getUrl(), item);
  return response.data;
};

const updateRiskAssessment = async (updates: RiskAssessmentUpdates): Promise<IRiskAssessmentEditVm> => {
  try {
    const response = await httpClient.getInstance().post(Routes.EditRiskAssessment.getPath(updates.token), updates.getPayload());
    return response.data;
  } catch (e) {
    console.error('updateRiskAssessment', e);
    return null;
  }
};

const uploadDocument = async (command: IEnsureRiskAssessmentDocument): Promise<IRiskAssessmentEditVm> => {
  const { tokenOrId, metadata } = command;
  if (metadata.id) {
    return editDocument(command);
  }

  const formData = getFormData(command);
  const result = await httpClient.getInstance().post(`/riskassessments/${tokenOrId}/documents`, formData, {
    headers: { 'Content-Type': 'multipart/form-data', Accept: 'multipart/form-data' },
  });
  return result.data;
};

const uploadDocuments = async (command: IUploadRiskAssessmentDocuments): Promise<IRiskAssessmentEditVm> => {
  const { tokenOrId } = command;
  const formData = getFormDataForMultiUpload(command);
  const result = await httpClient.getInstance().post(`/riskassessments/${tokenOrId}/documents`, formData, {
    headers: { 'Content-Type': 'multipart/form-data', Accept: 'multipart/form-data' },
  });
  return result.data;
};

const editDocument = async (command: IEnsureRiskAssessmentDocument): Promise<IRiskAssessmentEditVm> => {
  const { tokenOrId, metadata } = command;
  const formData = getFormData(command);

  const result = await httpClient.getInstance().put(`/riskassessments/${tokenOrId}/documents/${metadata.id}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data', Accept: 'multipart/form-data' },
  });
  return result.data;
};

const getFormData = (command: IEnsureRiskAssessmentDocument): FormData => {
  const { tokenOrId, metadata, file, updates } = command;
  const formData = new FormData();
  formData.append('Metadata', JSON.stringify(metadata));
  if (updates && updates.hasUpdates) {
    formData.append('RiskAssessmentUpdates', JSON.stringify(updates.getPayload()));
  }
  formData.append('FileStream', file == null ? null : (file.file as any));

  return formData;
};

const getFormDataForMultiUpload = (command: IUploadRiskAssessmentDocuments): FormData => {
  const { metadata, files, updates } = command;
  const formData = new FormData();
  formData.append('Metadata', JSON.stringify(metadata));
  if (updates && updates.hasUpdates) {
    formData.append('RiskAssessmentUpdates', JSON.stringify(updates.getPayload()));
  }

  _.forEach(files, (file) => {
    formData.append('FileStreams', file);
  });

  return formData;
};

const loadFile = async (riskAssessmentId: string, documentId: string, version: any): Promise<Blob> => {
  const result = await httpClient.getInstance().post<Blob>(
    `riskassessments/file/`,
    { DocumentId: documentId, RiskAssessmentId: riskAssessmentId, Version: version },
    {
      headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
      responseType: 'blob',
    },
  );
  return result.data;
};

const createStatusMonitorReport = async (filter: IRiskAssessmentListSearchConfig, reportType: string, language: ILanguage) => {
  const searchFilter = filter ?? createRiskAssessmentStatusMonitorSearchConfig();
  const result = await httpClient.getInstance().post<Blob>(`riskAssessments/statusReport/${reportType}/${language.key}`, searchFilter, { responseType: 'blob' });
  return result;
};

const getNewRiskAssessmentVm = async (branchOfIndustryId: string) => {
  try {
    const result = await httpClient.getInstance().post<INewRiskassessmentVm>(`/riskAssessments/models/newriskassessment`, { branchOfIndustryId: branchOfIndustryId });
    return result.data;
  } catch {
    return null;
  }
};

const isOfferNoValid = async (tenantId: string, branchId: string, offerNo: string, riskAssessmentId?: string): Promise<boolean> => {
  const result = await httpClient
    .getInstance()
    .post<IValidOfferNumberQueryResult>(`/riskAssessments/validoffernumbers`, { tenantId: tenantId, branchId: branchId, offerNumber: offerNo, riskAssessmentId: riskAssessmentId });
  return result.data.isValid;
};

interface IRiskAssessmentDepartmentHistoryQueryResult {
  history: IRiskAssessmentHistoryCollection;
}

const getEvaluationHistory = async (riskAssessmentId: string, departmentId: string) => {
  const response = await httpClient.getInstance().get<IRiskAssessmentDepartmentHistoryQueryResult>(`/riskassessments/${riskAssessmentId}/history/evaluations/${departmentId}`);
  return response.data.history;
};

interface IRiskAssessmentApprovalDataHistoryResult {
  history: IRiskAssessmentHistoryCollection;
}

const getApprovalDataHistory = async (riskAssessmentId: string) => {
  const response = await httpClient.getInstance().get<IRiskAssessmentApprovalDataHistoryResult>(`/riskassessments/${riskAssessmentId}/history/approvaldata`);
  return response.data.history;
};

interface IValidOfferNumberQueryResult {
  isValid: boolean;
}

export default {
  loadRiskAssessment: loadRiskAssessmentForEdit,
  getRiskAssessmentStatusMonitor,
  getRiskAssessmentList,
  getNewRiskAssessmentVm,
  createNewRiskAssessment,
  updateRiskAssessment,
  createStatusMonitorReport,
  uploadDocument,
  uploadDocuments,
  loadFile,
  isOfferNoValid,
  getEvaluationHistory,
  getApprovalDataHistory,
};
