import {
  RiskAssessmentStatus,
  IRiskAssessment,
  INotificationData,
  IMessageTemplate,
  IApprovalFlowStage,
  IApprovalFlow,
  getCurrentApprovalFlow,
  PoaData,
  TransitionData,
  ApprovalFlowTypes,
  PartialClearanceData,
  IAssessmentTransitionData,
  ByOrderOfData,
  FullClearanceData,
} from './IRiskAssessment';
import {
  IRiskAssessmentTemplate,
  IRiskAssessmentEvaluationFlow,
  IRiskAssessmentDepartmentEmailReceiverConfig,
  IApprovalStageTemplate,
  getApprovalStageForFlowById,
  IRiskAssessmentDepartment,
} from './IRiskAssessmentTemplate';
import _ from 'lodash';
import { LanguageSettings } from '../LanguageSettings';
import { getForLanguage } from '../IMultiLanguageString';

interface IMessageTexts {
  subject: string;
  body: string;
}

export type AssessmentTransition =
  | StatusTransition
  | ApprovalFlowStartTransition
  | ApprovalStageTransition
  | CompleteApprovalStage
  | ReopenApprovalStage
  | ReturnToEvaluation
  | ReleaseProjectForCommunication
  | NoProject
  | DeleteProject
  | MoveToApproved;

export class StatusTransition {
  constructor(public readonly toStatus: RiskAssessmentStatus, public readonly fromStatus?: RiskAssessmentStatus) {}
  static from(toStatus: RiskAssessmentStatus, fromStatus?: RiskAssessmentStatus): StatusTransition {
    return new StatusTransition(toStatus, fromStatus);
  }

  createNotificationData(assessment: IRiskAssessment, settings: LanguageSettings, messageTexts: IMessageTexts, mustEnsureRequiredDepartments: boolean): INotificationData {
    return createNotificationDataFromTemplate(
      assessment.processTemplate,
      getDepartmentReceiversFromEvaluationFlowTemplate(assessment.processTemplate.evaluationFlow, mustEnsureRequiredDepartments),
      true,
      settings,
      messageTexts,
    );
  }

  getMessageTemplate(assessment: IRiskAssessment): IMessageTemplate {
    return assessment.processTemplate.notifications.statusChangedTemplate;
  }
}

export class ApprovalFlowStartTransition {
  constructor(public readonly flow: IApprovalFlow, public readonly data: TransitionData) {}
  static from(flowToStart: IApprovalFlow, assessment: IRiskAssessment) {
    let transitionData = getTransitionDataFromAssessment(flowToStart, assessment);
    return new ApprovalFlowStartTransition(flowToStart, transitionData);
  }

  createNotificationData(assessment: IRiskAssessment, settings: LanguageSettings, messageTexts: IMessageTexts, mustEnsureRequiredDepartments: boolean): INotificationData {
    let targetStage = getApprovalStageForFlowById(assessment.processTemplate, this.flow.id, this.flow.stages[0].id);

    return createNotificationDataFromTemplate(assessment.processTemplate, getDepartmentReceiversFromApprovalStageTemplate(targetStage), true, settings, messageTexts);
  }

  getMessageTemplate(assessment: IRiskAssessment): IMessageTemplate {
    return assessment.processTemplate.notifications.projectApprovalTemplate;
  }
}

function getTransitionDataFromAssessment(flowToStart: IApprovalFlow, assessment: IRiskAssessment): TransitionData {
  let metadata = assessment.metadata;
  switch (flowToStart.flowType) {
    case ApprovalFlowTypes.PowerofAttorney:
    case ApprovalFlowTypes.DefaultWithPowerofAttorney: {
      let poaData = new PoaData();
      poaData.vendorPoa = metadata.vendorPoa;
      poaData.offerNrPoa = metadata.offerNrPoa;
      return poaData;
    }

    case ApprovalFlowTypes.PartialClearance: {
      let pcData = new PartialClearanceData();
      pcData.billRecipient = metadata.billRecipient;
      pcData.deliveryTimes = metadata.deliveryTimes;
      pcData.signatureDate = metadata.signatureDate;
      pcData.estimatedDateOfTurnover = metadata.estimatedDateOfTurnover;
      pcData.indicationOfMainScope = metadata.indicationOfMainScope;
      return pcData;
    }

    case ApprovalFlowTypes.FullClearance: {
      let fcData = new FullClearanceData();
      fcData.billRecipient = metadata.billRecipient;
      fcData.deliveryTimes = metadata.deliveryTimes;
      fcData.projectStart = metadata.projectStart;
      fcData.signatureDate = metadata.signatureDate;
      fcData.estimatedDateOfTurnover = metadata.estimatedDateOfTurnover;
      fcData.indicationOfMainScope = metadata.indicationOfMainScope;
      return fcData;
    }
  }

  return null;
}

export class ApprovalStageTransition {
  constructor(public readonly toStage: IApprovalFlowStage, public readonly fromStage?: IApprovalFlowStage) {}
  static from(toStage: IApprovalFlowStage, fromStage?: IApprovalFlowStage) {
    return new ApprovalStageTransition(toStage, fromStage);
  }

  createNotificationData(assessment: IRiskAssessment, settings: LanguageSettings, messageTexts: IMessageTexts): INotificationData {
    let flow = getCurrentApprovalFlow(assessment);
    let targetStage = getApprovalStageForFlowById(assessment.processTemplate, flow.id, this.toStage.id);
    return createNotificationDataFromTemplate(assessment.processTemplate, getDepartmentReceiversFromApprovalStageTemplate(targetStage), true, settings, messageTexts);
  }

  getMessageTemplate(assessment: IRiskAssessment): IMessageTemplate {
    return assessment.processTemplate.notifications.projectApprovalTemplate;
  }
}

export class CompleteAssessmentAnalysis extends StatusTransition {
  constructor(fromStatus: RiskAssessmentStatus) {
    super(RiskAssessmentStatus.Approved, fromStatus);
  }

  createNotificationData(assessment: IRiskAssessment, settings: LanguageSettings, messageTexts: IMessageTexts): INotificationData {
    const recipients = _.concat(this.getCompletedDepartmentRecipients(assessment));
    return createNotificationDataFromTemplate(assessment.processTemplate, recipients, true, settings, messageTexts);
  }

  getMessageTemplate(assessment: IRiskAssessment): IMessageTemplate {
    return assessment.processTemplate.notifications.projectApprovedTemplate;
  }

  getCompletedDepartmentRecipients(assessment: IRiskAssessment): IRiskAssessmentDepartmentEmailReceiverConfig[] {
    let recipients: IRiskAssessmentDepartmentEmailReceiverConfig[] = [];
    const currentFlow = getCurrentApprovalFlow(assessment);
    const completedStages = _.filter(currentFlow.stages, (s) => s.isCompleted && s.id != assessment.approvalFlows.currentStageId).map((c) =>
      getApprovalStageForFlowById(assessment.processTemplate, currentFlow.id, c.id),
    );
    _.map(completedStages, (c) => (recipients = _.concat(recipients, getDepartmentReceiversFromApprovalStageTemplate(c))));
    return recipients;
  }
}

export class ReturnToEvaluation extends StatusTransition {
  constructor(fromStatus: RiskAssessmentStatus) {
    super(RiskAssessmentStatus.Evaluation, fromStatus);
  }

  createNotificationData(assessment: IRiskAssessment, settings: LanguageSettings, messageTexts: IMessageTexts, mustEnsureRequiredDepartments: boolean): INotificationData {
    return createNotificationDataFromTemplate(assessment.processTemplate, assessment.processTemplate.salesDepartments?.departments, true, settings, messageTexts);
  }

  getMessageTemplate(assessment: IRiskAssessment): IMessageTemplate {
    return assessment.processTemplate.notifications.statusChangedTemplate;
  }
}

export class ReleaseProjectForCommunication extends StatusTransition {
  constructor(fromStatus: RiskAssessmentStatus) {
    super(RiskAssessmentStatus.Assessment, fromStatus);
  }

  createNotificationData(assessment: IRiskAssessment, settings: LanguageSettings, messageTexts: IMessageTexts, mustEnsureRequiredDepartments: boolean): INotificationData {
    return createNotificationDataFromTemplate(
      assessment.processTemplate,
      getDepartmentReceiversFromEvaluationFlowTemplate(assessment.processTemplate.evaluationFlow, mustEnsureRequiredDepartments),
      false,
      settings,
      messageTexts,
    );
  }

  getMessageTemplate(assessment: IRiskAssessment): IMessageTemplate {
    return assessment.processTemplate.notifications.statusChangedTemplate;
  }
}

export class NoProject extends StatusTransition {
  constructor(fromStatus: RiskAssessmentStatus) {
    super(RiskAssessmentStatus.Rejected, fromStatus);
  }

  createNotificationData(assessment: IRiskAssessment, settings: LanguageSettings, messageTexts: IMessageTexts, mustEnsureRequiredDepartments: boolean): INotificationData {
    return createNotificationDataFromTemplate(
      assessment.processTemplate,
      getDepartmentReceiversFromEvaluationFlowTemplate(assessment.processTemplate.evaluationFlow, mustEnsureRequiredDepartments),
      false,
      settings,
      messageTexts,
    );
  }

  getMessageTemplate(assessment: IRiskAssessment): IMessageTemplate {
    return null;
  }
}

export class DeleteProject extends StatusTransition {
  constructor(fromStatus: RiskAssessmentStatus) {
    super(RiskAssessmentStatus.Deleted, fromStatus);
  }

  createNotificationData(assessment: IRiskAssessment, settings: LanguageSettings, messageTexts: IMessageTexts, mustEnsureRequiredDepartments: boolean): INotificationData {
    return createNotificationDataFromTemplate(
      assessment.processTemplate,
      getDepartmentReceiversFromEvaluationFlowTemplate(assessment.processTemplate.evaluationFlow, mustEnsureRequiredDepartments),
      false,
      settings,
      messageTexts,
    );
  }

  getMessageTemplate(assessment: IRiskAssessment): IMessageTemplate {
    return null;
  }
}

export class MoveToApproved {
  constructor(fromStatus: RiskAssessmentStatus) {}

  createNotificationData(assessment: IRiskAssessment, settings: LanguageSettings, messageTexts: IMessageTexts, mustEnsureRequiredDepartments: boolean): INotificationData {
    return createNotificationDataFromTemplate(
      assessment.processTemplate,
      getDepartmentReceiversFromEvaluationFlowTemplate(assessment.processTemplate.evaluationFlow, mustEnsureRequiredDepartments),
      false,
      settings,
      messageTexts,
    );
  }

  getMessageTemplate(assessment: IRiskAssessment): IMessageTemplate {
    return null;
  }
}

export class CompleteApprovalStage {
  constructor(public approvalStage: IApprovalFlowStage) {}

  createNotificationData(assessment: IRiskAssessment, settings: LanguageSettings, messageTexts: IMessageTexts): INotificationData {
    return {} as INotificationData;
  }

  getMessageTemplate(assessment: IRiskAssessment): IMessageTemplate {
    return null;
  }

  getTransitionData(): TransitionData {
    return new ByOrderOfData();
  }
}

export class ReopenApprovalStage {
  constructor(public approvalStage: IApprovalFlowStage) {}

  createNotificationData(assessment: IRiskAssessment, settings: LanguageSettings, messageTexts: IMessageTexts): INotificationData {
    return {} as INotificationData;
  }

  getMessageTemplate(assessment: IRiskAssessment): IMessageTemplate {
    return null;
  }
}

function getDepartmentReceiversFromEvaluationFlowTemplate(template: IRiskAssessmentEvaluationFlow, mustEnsureRequiredDepartments: boolean): IRiskAssessmentDepartmentEmailReceiverConfig[] {
  return _.map(template.stages, (stage) => {
    const mustParticipate = mustEnsureRequiredDepartments && (stage.isAlwaysInvolved || stage.isAssessmentRequired);
    const result: IRiskAssessmentDepartmentEmailReceiverConfig = {
      id: stage.id,
      name: stage.name,
      hasEmailReceivers: stage.hasEmailReceivers,
      isRequired: mustEnsureRequiredDepartments && (stage.isRequired || mustParticipate),
      mustParticipate: mustParticipate,
    };
    return result;
  });
}

function getDepartmentReceiversFromSalesDepartments(sale: IRiskAssessmentDepartment[]): IRiskAssessmentDepartmentEmailReceiverConfig[] {
  return _.map(sale, (d) => {
    const result: IRiskAssessmentDepartmentEmailReceiverConfig = {
      id: d.id,
      name: d.name,
      hasEmailReceivers: d.hasEmailReceivers,
      isRequired: true,
      mustParticipate: false,
    };
    return result;
  });
}

function getDepartmentReceiversFromApprovalStageTemplate(template: IApprovalStageTemplate): IRiskAssessmentDepartmentEmailReceiverConfig[] {
  const result: IRiskAssessmentDepartmentEmailReceiverConfig = {
    id: template.department.id,
    name: template.department.name,
    hasEmailReceivers: template.hasEmailReceivers,
    isRequired: true,
    mustParticipate: true,
  };
  return [result];
}

function createNotificationDataFromTemplate(
  template: IRiskAssessmentTemplate,
  receivers: IRiskAssessmentDepartmentEmailReceiverConfig[],
  notificationToCreator: boolean,
  settings: LanguageSettings,
  messageTexts: IMessageTexts,
): INotificationData {
  const sorted = _.sortBy(receivers, (d) => getForLanguage(d.name, settings));

  const data: INotificationData = {
    messageData: { subject: messageTexts.subject, body: messageTexts.body },
    departmentReceivers: sorted,
    additionalReceivers: [],
    notificationToCreator: notificationToCreator,
  };

  return data;
}

export function getTransitionData(transition: AssessmentTransition): TransitionData | null {
  if (transition instanceof ApprovalFlowStartTransition) {
    return transition.data;
  }

  if (transition instanceof CompleteApprovalStage) {
    return transition.getTransitionData();
  }

  return null;
}
