import { User } from 'oidc-client';
import { combineReducers } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import { IUserState, userReducer } from '../users/ducks/reducer';
import * as actions from './userAction';
import * as branchActions from '../branchselection/actions';
import { createSelector } from 'reselect';
import _ from 'lodash';
import { IUserSetting } from '../models/masterdata';
import { UserInfo } from '../models/user/UserInfo';
import { UserPermissions } from '../models';
import { IRiskAssessmentState, riskAssessmentReducer } from '../riskAssessments/ducks';

export interface IAppState {
  user: User | undefined;
  userInfo: UserInfo;
}

type AppActions = ActionType<typeof actions>;
type BranchActions = ActionType<typeof branchActions>;

export const appReducer = combineReducers<IAppState, AppActions>({
  user: (state: User | undefined = null, action: AppActions) => {
    switch (action.type) {
      case getType(actions.loginUser):
        state = action.payload.user;
        return state;
      default:
        return state;
    }
  },
  userInfo: (state: UserInfo = null, action: AppActions | BranchActions) => {
    let stateClone = null;
    switch (action.type) {
      case getType(actions.loginUser):
        state = action.payload.userInfo;
        return state;

      case getType(branchActions.selectBranchOfIndustrySettingForUser.success):
        stateClone = state.clone();
        stateClone.userSetting = action.payload.userSetting;
        stateClone.permissions = UserPermissions.fromUserInfo(stateClone);
        return stateClone;
      case getType(branchActions.selectLanguageForUser.success):
        stateClone = state.clone();
        stateClone.userSetting = action.payload.userSetting;
        stateClone.permissions = UserPermissions.fromUserInfo(stateClone);
        return stateClone;
      default:
        return state;
    }
  },
});

export const getUserInfo = (state: IAppState): UserInfo => (state && state.userInfo) || new UserInfo();
export const getUserSetting = createSelector([getUserInfo], (userInfo) => userInfo.userSetting || ({} as IUserSetting));

export const getUserPermissions = createSelector([getUserInfo], (userInfo) => userInfo.permissions || UserPermissions.empty());

export const getAvailableTenants = createSelector([getUserInfo], (userInfo) => userInfo.availableTenants || []);

const getActiveTenantId = createSelector([getUserInfo], (userInfo) => userInfo.userSetting && userInfo.userSetting.activeTenantId);
export const getActiveTenant = createSelector([getActiveTenantId, getAvailableTenants], (activeTenantId, availableTenants) => {
  if (!availableTenants || availableTenants.length === 0) {
    return null;
  }
  if (availableTenants.length === 1) {
    return availableTenants[0];
  }

  if (activeTenantId) {
    return _.find(availableTenants, (t) => t.id === activeTenantId);
  }
  return null;
});

export const getAllTenants = createSelector([getUserInfo], (userInfo) => userInfo.allTenants || []);

export const getAvailableBranchesOfIndustry = createSelector([getActiveTenant], (activeTenant) => {
  if (!activeTenant) {
    return [];
  }

  return activeTenant.assignedBranchesOfIndustry || [];
});

export const getVisibleBranchesOfIndustry = createSelector([getActiveTenant], (activeTenant) => {
  if (!activeTenant) {
    return [];
  }

  return activeTenant.visibleBranchesOfIndustry || [];
});

export const getActiveBranchOfIndustryId = createSelector([getUserSetting, getActiveTenantId], (userSetting, activeTenantId) => {
  return (userSetting.activeBranchOfIndustryForTenantMap && userSetting.activeBranchOfIndustryForTenantMap[activeTenantId]) || '';
});

export const getActiveBranchOfIndustry = createSelector([getActiveBranchOfIndustryId, getAvailableBranchesOfIndustry], (activeBranchId, availableBranches) => {
  if (!availableBranches || availableBranches.length === 0) {
    return null;
  }
  if (availableBranches.length === 1) {
    return availableBranches[0];
  }

  if (activeBranchId) {
    return _.find(availableBranches, (t) => t.id === activeBranchId);
  }
  return null;
});

export const getOfferNumbersOfRiskAssessments = (state: IRootState) =>
  state.riskAssessment.riskAssessmentListView != null ? _.map(state.riskAssessment.riskAssessmentListView.riskAssessments, (r) => r.offerNr.toLowerCase()) : [];
export interface IRootState {
  app: IAppState;
  user: IUserState;
  riskAssessment: IRiskAssessmentState;
}

const rootReducer = combineReducers<IRootState>({
  app: appReducer,
  user: userReducer,
  riskAssessment: riskAssessmentReducer,
});

export default rootReducer;
