import { AxiosInstance } from 'axios';
import { User, UserManager } from 'oidc-client';
import { Store } from 'redux';
import { createIUserAndSettingsParam, loginUser } from '../../ducks/userAction';
import { IEnsureUserSettingCommand, IUserSetting, settingsWithDefaults, IEnsureTenantBranchDefaultCommand, IEnsureUserLanguageCommand, ITenantTerms, IBranchOfIndustry } from '../../models/masterdata';
import httpClient from './httpClient';
import SettingsService from './settingsService';
import { IRootState } from '../../ducks/reducer';
import { IUserSettingResult, IUserInfoResult, IAvailableAzureUsersResult, IAzureUserRef, IAzureUser, IUserViewModelRef, IUserViewModelRefResult, ICanDeleteResult } from '../../models/user';
import { UserInfo } from '../../models/user/UserInfo';
import _ from 'lodash';
import LRU from 'lru-cache';
import { IBranchOfIndustryInfo, IUserTenantInfo } from '../../models/masterdata/ITenant';
import { ILanguage } from '../../models/LanguageSettings';
import { matchesQuery } from './masterdataQueryHelper';

const RetryStorageKey = 'UserService_RetryStorageKey';
class UserService {
  private cacheOptions = { maxAge: 1000 * 180 }; // milli seconds
  private azureUserCache = new LRU(this.cacheOptions);
  private userManager: UserManager = {} as UserManager;
  private currentUser: User;
  private currentUserSettings: IUserSetting;
  private store: Store<IRootState> = {} as Store<IRootState>;
  private http: AxiosInstance;
  private loggingIn = false;
  private userInfo: UserInfo;

  public async init(appStore: Store): Promise<User> {
    this.loggingIn = false;
    this.userManager = this.createUserManager();
    this.store = appStore;
    try {
      const user = await this.userManager.getUser();
      if (!user || user.expired) {
        this.login();
        return null;
      }
      this.currentUser = user;
      this.http = httpClient.getInstance(true); // instance aktualisieren mit den user daten
      this.userInfo = await this.getUserInfo(user);
      this.currentUserSettings = settingsWithDefaults(this.userInfo.userSetting);
      this.http = httpClient.getInstance(true); // instance aktualisieren mit den userinfo daten
      await this.store.dispatch(loginUser(createIUserAndSettingsParam(user, this.userInfo)));
      return user;
    } catch (error) {
      console.log(error);
      if (this.canRetry()) {
        this.login();
      }

      return null;
    }
  }

  public canRetry = (): boolean => {
    // We have to avoid the redirect loop of death
    // But we still should try it a few times
    const currentCount = +sessionStorage.getItem(RetryStorageKey) || 0;
    if (currentCount < 3) {
      sessionStorage.setItem(RetryStorageKey, (currentCount + 1).toString());
      return true;
    }

    // After one error page you can try again, so we need to remove the key
    sessionStorage.removeItem(RetryStorageKey);
    return false;
  };

  public login = () => {
    this.loggingIn = true;
    this.userManager.signinRedirect({ state: window.location.href });
  };

  public getUserInfoObject = () => {
    return this.userInfo;
  };

  public isLoggingIn = () => this.loggingIn;

  public logout = () => this.userManager.signoutRedirect();

  public signinSilent = async (): Promise<User> => {
    return this.userManager.signinSilent();
  };

  public getCurrentUser = (): User => {
    return this.currentUser;
  };

  public getCurrentUserSettings = (): IUserSetting => {
    const state = this.store.getState();

    return (state.app && state.app.userInfo && state.app.userInfo.userSetting) || this.currentUserSettings || ({} as IUserSetting);
  };

  public getActiveBranchOfIndustryId = (): string => {
    const setting = this.getCurrentUserSettings();
    if (!setting.activeBranchOfIndustryForTenantMap) {
      return null;
    }

    return setting.activeBranchOfIndustryForTenantMap[setting.activeTenantId];
  };

  public getAvailableBranchesOfIndustry = (): IBranchOfIndustryInfo[] => this.getActiveTenantInfo()?.assignedBranchesOfIndustry;
  public getVisibleBranchesOfIndustry = (): IBranchOfIndustryInfo[] => this.getActiveTenantInfo()?.visibleBranchesOfIndustry;

  public getAvailableBranchesOfIndustryByTenantId = (tenantId: string): IBranchOfIndustryInfo[] => {
    const activeTenantInfo = this.getActiveTenantInfo();
    return activeTenantInfo?.assignedBranchesOfIndustry ?? [];
  };
  public getImageForTenant = (tenantId: string): string => {
    const state = this.store.getState();
    const tenants = state.app.userInfo.allTenants;
    return _.find(tenants, (t) => t.id === tenantId).logo;
  };
  public ensureUserSetting = async (usersettingUpdateCommand: Partial<IEnsureUserSettingCommand>): Promise<IUserSetting> => {
    const result = await this.http.put<IUserSettingResult>(`usersettings/${usersettingUpdateCommand.subjectId}`, usersettingUpdateCommand);
    return result.data.userSetting;
  };
  public createUserReport = async (language: ILanguage) => {
    const result = await this.http.get<Blob>(`users/report/${language.key}`, { responseType: 'blob' });
    return result;
  };
  public ensureTenantBranchDefault = async (tenantBranchDefaultCommand: IEnsureTenantBranchDefaultCommand): Promise<IUserSetting> => {
    const result = await this.http.put<IUserSettingResult>(`usersettings/tenantbranchdefault/${tenantBranchDefaultCommand.subjectId}`, tenantBranchDefaultCommand);
    return result.data.userSetting;
  };

  public ensureUserLanguage = async (userLanguageCommand: IEnsureUserLanguageCommand): Promise<IUserSetting> => {
    const result = await this.http.put<IUserSettingResult>(`usersettings/userLanguage/${userLanguageCommand.subjectId}`, userLanguageCommand);
    return result.data.userSetting;
  };

  private getUserInfo = async (user: User): Promise<UserInfo> => {
    const result = await this.http.get<IUserInfoResult>(`users/me`);
    return UserInfo.from(result.data);
  };

  public getAvailableUsers = async (): Promise<IAzureUser[]> => {
    const cached = this.azureUserCache.get('azureUsers') as IAzureUser[];
    if (cached != null) {
      return cached;
    }
    const result = await this.http.get<IAvailableAzureUsersResult>(`users/availableusers`);

    if (result.data && result.data.users && result.data.users.length > 0) {
      this.azureUserCache.set('azureUsers', result.data.users);
    }
    return result.data.users;
  };

  public getUserOptions = async (query: string, tenantId: string, branchId: string): Promise<IUserViewModelRef[]> => {
    const result: any = await this.http.get<IUserViewModelRefResult>(`users/options?query=${query}&tenantId=${tenantId}&branchId=${branchId}`);
    return result.data.users;
  };

  public queryAvailableAzureUsersOptions = async (query: string = ''): Promise<IAzureUserRef[]> => {
    let users = await this.getAvailableUsers();
    if (!users || users.length === 0) {
      return [];
    }
    if (query) {
      users = _.filter(users, (u) => matchesQuery(query, u.displayName));
    }
    users = _.take(users, 25);
    return users.map((u) => {
      return {
        id: u.id,
        name: u.displayName,
        user: u,
      };
    });
  };

  public async canDeleteUser(userId: string): Promise<ICanDeleteResult> {
    try {
      const result = await httpClient.getInstance().get<ICanDeleteResult>(`users/${userId}/candelete`);
      return result.data;
    } catch (error) {
      console.error('Error occurred:', error);
      return null;
    }
  }

  public async deleteUser(userId: string): Promise<boolean> {
    try {
      const result = await httpClient.getInstance().delete<boolean>(`users/${userId}`);
      return result.data;
    } catch {
      return false;
    }
  }

  public async getUserAdData(userId: string): Promise<IAzureUser> {
    try {
      const result = await httpClient.getInstance().get<IAzureUser>(`users/${userId}/addata`);
      return result.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  public isActiveTenant = (tenantId: string): boolean => {
    return this.getActiveTenantId() === tenantId;
  };

  public getActiveTenantId = (): string => {
    return this.currentUserSettings && this.currentUserSettings.activeTenantId;
  };

  public getActiveTenantInfo = (): IUserTenantInfo => {
    const state = this.store.getState();
    const tenants = state.app.userInfo.availableTenants;
    const activeTenantId = this.getActiveTenantId();
    return _.find(tenants, (t) => t.id === activeTenantId);
  };

  public getSelectedTenantInfo = (tenantId: string): IUserTenantInfo => {
    const state = this.store.getState();
    const tenants = state.app.userInfo.availableTenants;
    return _.find(tenants, (t) => t.id === tenantId);
  };

  public hasVendorLegalEntity = (): boolean => {
    const info = this.getActiveTenantInfo();
    return info && info.hasVendorLegalEntity;
  };

  public hasCustomerDescription = (): boolean => {
    return this.getActiveTenantInfo()?.hasCustomerDescription;
  };

  public hasMachineType = (): boolean => {
    const info = this.getActiveTenantInfo();
    return info && info.hasMachineType;
  };

  public getTenantTerms = (): ITenantTerms => {
    const info = this.getActiveTenantInfo();
    return info && info.terms;
  };

  private createUserManager = () => {
    const appConfig = SettingsService.getConfig();
    const config = {
      authority: appConfig.identity.authority,
      client_id: 'js',
      redirect_uri: `${appConfig.identity.redirecBaseUrl}/callback.html`,
      response_type: 'code',
      scope: 'openid profile api offline_access',
      post_logout_redirect_uri: `${appConfig.identity.redirecBaseUrl}/index.html`,
      automaticSilentRenew: true,
      silent_redirect_uri: `${appConfig.identity.redirecBaseUrl}/silentrenew.html`,
    };
    const um = new UserManager(config);
    um.events.addSilentRenewError(this.handleSilentRenewError);
    um.events.addAccessTokenExpired(this.handleAccessTokenExpired);
    um.events.addAccessTokenExpiring(this.handleAccessTokenExpiring);
    return um;
  };
  private handleSilentRenewError = (ev: Error) => {
    console.log('silent renew failed');
    console.log(ev);
  };
  private handleAccessTokenExpired = (ev: any[]) => {
    console.log('access token expired');
    console.log(ev);
  };
  private handleAccessTokenExpiring = (ev: any[]) => {
    console.log('access token expiring');
    this.userManager.startSilentRenew();
  };
}

export const userService = new UserService();
