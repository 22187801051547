import {
  IRiskAssessment,
  RiskAssessmentStatus,
  IChangeStatusData,
  INotificationData,
  IAssessmentTransitionData,
  IRiskAssessmentApprovalStageRef,
  getCurrentApprovalStages,
  ByOrderOfData,
} from './IRiskAssessment';

import _ from 'lodash';
import { IDepartmentRef } from '../masterdata';
import { StatusTransition, ApprovalStageTransition, ApprovalFlowStartTransition, CompleteApprovalStage, ReopenApprovalStage, MoveToApproved } from './AssessmentTransition';
import { DocumentMetadataOwnerTypes } from '../documents/IDocumentMetaData';

type UpdateTypes =
  | 'CalculationUpdate'
  | 'MetadataUpdate'
  | 'CategorizationUpdate'
  | 'DepartmentEvaluationUpdate'
  | 'ApprovalUpdate'
  | 'DepartmentEvaluationCompleteUpdate'
  | 'DepartmentEvaluationReopenUpdate'
  | 'DepartmentEvaluationSummaryUpdate'
  | 'ShareProjectForCommunication'
  | 'MoveToEvaluation'
  | 'NoProject'
  | 'MoveToApproved'
  | 'StartApprovalFlow'
  | 'MoveToApprovalStage'
  | 'Approve'
  | 'ApprovalStageCompleteUpdate'
  | 'ApprovalStageReopenUpdate'
  | 'CheckInDocumentUpdate'
  | 'CheckOutDocumentUpdate'
  | 'RevertCheckOutDocumentUpdate'
  | 'ArchiveDocumentUpdate'
  | 'RevertArchiveDocumentUpdate'
  | 'DeleteProject'
  | 'DeleteDocumentUpdate';

export interface IRiskAssessmentUpdate {
  type: UpdateTypes;
  department?: IDepartmentRef;
  toStage?: IRiskAssessmentApprovalStageRef;
  fromStage?: IRiskAssessmentApprovalStageRef;
  id?: IRiskAssessmentApprovalStageRef;
  approvalFlowId?: string;
}

export interface IRiskAssessmentStatusUpdate extends IRiskAssessmentUpdate {
  notificationData: INotificationData;
}
export interface IRiskAssessmentUpdateByOrder extends IRiskAssessmentUpdate {
  byOrderOf: string;
}

export interface IRiskAssessmentDocumentUpdate extends IRiskAssessmentUpdate {
  owner: DocumentMetadataOwnerTypes;
  assessmentId: string;
  documentId: string;
}

export class RiskAssessmentUpdates {
  private constructor(public token: string, public updates: IRiskAssessmentUpdate[] = []) {}

  get hasUpdates(): boolean {
    return this.updates.length > 0;
  }

  static from(original: IRiskAssessment, newValues: IRiskAssessment): RiskAssessmentUpdates {
    const updates = getRiskAssessmentUpdates(original, newValues);

    return new RiskAssessmentUpdates(original.token, updates);
  }

  static hasUpdates(original: IRiskAssessment, newValues: IRiskAssessment): boolean {
    return RiskAssessmentUpdates.from(original, newValues).hasUpdates;
  }

  static hasOfferNrChanged(original: IRiskAssessment, newValues: IRiskAssessment): boolean {
    return original.metadata.offerNr !== newValues.metadata.offerNr;
  }

  public getPayload() {
    return {
      updates: this.updates,
    };
  }

  public withStatus(data: IChangeStatusData): RiskAssessmentUpdates {
    switch (data.toStatus) {
      case RiskAssessmentStatus.Rejected:
        this.updates.push(createNoProject(data.notificationData));
        break;
      case RiskAssessmentStatus.Approval:
        this.updates.push(createApproval(data.notificationData));
        break;
      case RiskAssessmentStatus.Approved:
        this.updates.push(createApproved(data.notificationData));
        break;
      case RiskAssessmentStatus.Assessment:
        this.updates.push(createAssessment(data.notificationData));
        break;
      case RiskAssessmentStatus.Evaluation:
        this.updates.push(createEvaluation(data.notificationData));
        break;
      case RiskAssessmentStatus.Deleted:
        this.updates.push(createDeleteProject(data.notificationData));
    }
    return this;
  }

  public withTransition(data: IAssessmentTransitionData): RiskAssessmentUpdates {
    if (data.transition instanceof StatusTransition) {
      this.withStatus({ toStatus: data.transition.toStatus, notificationData: data.notificationData });
    } else if (data.transition instanceof ApprovalFlowStartTransition) {
      this.updates.push(createStartApprovalFlow(data));
    } else if (data.transition instanceof ApprovalStageTransition) {
      this.updates.push(createMoveToApprovalStage(data));
    } else if (data.transition instanceof CompleteApprovalStage) {
      this.updates.push(completeApprovalStage(data));
    } else if (data.transition instanceof ReopenApprovalStage) {
      this.updates.push(reopenApprovalStage(data.transition.approvalStage));
    } else if (data.transition instanceof MoveToApproved) {
      this.updates.push(moveToApproved(data.notificationData));
    } else {
      console.error('Unknown Transition', data.transition);
    }

    return this;
  }

  public completeEvaluation(department: IDepartmentRef): RiskAssessmentUpdates {
    this.updates.push(createCompletedEvaluation(department));
    return this;
  }

  public reopenEvaluation(department: IDepartmentRef): RiskAssessmentUpdates {
    this.updates.push(createReopenEvaluation(department));
    return this;
  }

  public checkInDocument(assessmentId: string, documentId: string, owner: DocumentMetadataOwnerTypes): RiskAssessmentUpdates {
    this.updates.push(createCheckInDocument(assessmentId, documentId, owner));
    return this;
  }
  public checkOutDocument(assessmentId: string, documentId: string, owner: DocumentMetadataOwnerTypes): RiskAssessmentUpdates {
    this.updates.push(createCheckOutDocument(assessmentId, documentId, owner));
    return this;
  }
  public revertCheckOutDocument(assessmentId: string, documentId: string, owner: DocumentMetadataOwnerTypes): RiskAssessmentUpdates {
    this.updates.push(createRevertCheckOutDocument(assessmentId, documentId, owner));
    return this;
  }
  public archiveDocument(assessmentId: string, documentId: string, owner: DocumentMetadataOwnerTypes): RiskAssessmentUpdates {
    this.updates.push(createArchiveDocument(assessmentId, documentId, owner));
    return this;
  }
  public revertArchiveDocument(assessmentId: string, documentId: string, owner: DocumentMetadataOwnerTypes): RiskAssessmentUpdates {
    this.updates.push(createRevertArchiveDocument(assessmentId, documentId, owner));
    return this;
  }
  public deleteDocument(assessmentId: string, documentId: string, owner: DocumentMetadataOwnerTypes): RiskAssessmentUpdates {
    this.updates.push(createDeleteDocument(assessmentId, documentId, owner));
    return this;
  }
}

const createNoProject = (notificationData: INotificationData): IRiskAssessmentStatusUpdate => ({ type: 'NoProject', notificationData });
const createApproval = (notificationData: INotificationData): IRiskAssessmentStatusUpdate => ({ type: 'MoveToApprovalStage', notificationData });
const createApproved = (notificationData: INotificationData): IRiskAssessmentStatusUpdate => ({ type: 'Approve', notificationData });
const createAssessment = (notificationData: INotificationData): IRiskAssessmentStatusUpdate => ({ type: 'ShareProjectForCommunication', notificationData });
const createEvaluation = (notificationData: INotificationData): IRiskAssessmentStatusUpdate => ({ type: 'MoveToEvaluation', notificationData });
const createDeleteProject = (notificationData: INotificationData): IRiskAssessmentStatusUpdate => ({ type: 'DeleteProject', notificationData });

const moveToApproved = (notificationData: INotificationData): IRiskAssessmentStatusUpdate => ({ type: 'MoveToApproved', notificationData });
const createMoveToApprovalStage = (data: IAssessmentTransitionData): IRiskAssessmentStatusUpdate => ({
  type: 'MoveToApprovalStage',
  toStage: (data.transition as ApprovalStageTransition).toStage,
  notificationData: data.notificationData,
});

const createStartApprovalFlow = (data: IAssessmentTransitionData): IRiskAssessmentStatusUpdate => {
  return {
    type: 'StartApprovalFlow',
    approvalFlowId: (data.transition as ApprovalFlowStartTransition).flow.id,
    notificationData: data.notificationData,
  };
};

const createCompletedEvaluation = (department: IDepartmentRef): IRiskAssessmentUpdate => ({ type: 'DepartmentEvaluationCompleteUpdate', department });
const createReopenEvaluation = (department: IDepartmentRef): IRiskAssessmentUpdate => ({ type: 'DepartmentEvaluationReopenUpdate', department });
const completeApprovalStage = (data: IAssessmentTransitionData): IRiskAssessmentUpdateByOrder => ({
  type: 'ApprovalStageCompleteUpdate',
  id: (data.transition as CompleteApprovalStage).approvalStage,
  byOrderOf: (data.transitionData as ByOrderOfData).byOrderOf && (data.transitionData as ByOrderOfData).byOrderOf.id,
});
const reopenApprovalStage = (approvalStage: IRiskAssessmentApprovalStageRef): IRiskAssessmentUpdate => ({
  type: 'ApprovalStageReopenUpdate',
  id: approvalStage,
});

const createCheckInDocument = (assessmentId: string, documentId: string, owner: DocumentMetadataOwnerTypes): IRiskAssessmentDocumentUpdate => ({
  type: 'CheckInDocumentUpdate',
  assessmentId,
  documentId,
  owner,
});
const createCheckOutDocument = (assessmentId: string, documentId: string, owner: DocumentMetadataOwnerTypes): IRiskAssessmentDocumentUpdate => ({
  type: 'CheckOutDocumentUpdate',
  assessmentId,
  documentId,
  owner,
});
const createRevertCheckOutDocument = (assessmentId: string, documentId: string, owner: DocumentMetadataOwnerTypes): IRiskAssessmentDocumentUpdate => ({
  type: 'RevertCheckOutDocumentUpdate',
  assessmentId,
  documentId,
  owner,
});
const createArchiveDocument = (assessmentId: string, documentId: string, owner: DocumentMetadataOwnerTypes): IRiskAssessmentDocumentUpdate => ({
  type: 'ArchiveDocumentUpdate',
  assessmentId,
  documentId,
  owner,
});
const createRevertArchiveDocument = (assessmentId: string, documentId: string, owner: DocumentMetadataOwnerTypes): IRiskAssessmentDocumentUpdate => ({
  type: 'RevertArchiveDocumentUpdate',
  assessmentId,
  documentId,
  owner,
});
const createDeleteDocument = (assessmentId: string, documentId: string, owner: DocumentMetadataOwnerTypes): IRiskAssessmentDocumentUpdate => ({
  type: 'DeleteDocumentUpdate',
  assessmentId,
  documentId,
  owner,
});

function getRiskAssessmentUpdates(original: IRiskAssessment, newValues: IRiskAssessment): IRiskAssessmentUpdate[] {
  const updates: IRiskAssessmentUpdate[] = [];

  if (!_.isEqual(original.calculation, newValues.calculation)) {
    const update: IRiskAssessmentUpdate = { type: 'CalculationUpdate' };
    updates.push(_.assign({}, newValues.calculation, update));
  }

  if (!_.isEqual(original.metadata, newValues.metadata)) {
    const update: IRiskAssessmentUpdate = { type: 'MetadataUpdate' };
    updates.push(_.assign({}, newValues.metadata, update));
  }

  if (!_.isEqual(original.categorization, newValues.categorization)) {
    const update: IRiskAssessmentUpdate = { type: 'CategorizationUpdate' };
    updates.push(_.assign({}, newValues.categorization, update));
  }

  _.forEach(getChangedDepartmentEvaluations(original, newValues), (ev) => {
    const update: IRiskAssessmentUpdate = { type: 'DepartmentEvaluationUpdate' };
    updates.push(_.assign({}, ev, update));
  });

  _.forEach(getChangedApprovals(original, newValues), (ev) => {
    const update: IRiskAssessmentUpdate = { type: 'ApprovalUpdate' };
    updates.push(_.assign({}, ev, update));
  });

  _.forEach(getChangedDepartmentEvaluationSummaries(original, newValues), (es) => {
    const update: IRiskAssessmentUpdate = { type: 'DepartmentEvaluationSummaryUpdate' };
    updates.push(_.assign({}, es, update));
  });

  return updates;
}

function getChangedDepartmentEvaluations(original: IRiskAssessment, newValues: IRiskAssessment) {
  if (newValues.evaluation == null || original.evaluation == null) {
    return null;
  }
  return _.filter(
    newValues.evaluation.evaluations,
    (e) =>
      !_.isEqual(
        e,
        _.find(original.evaluation.evaluations, (oe) => oe.department.id === e.department.id),
      ),
  );
}
function getChangedApprovals(original: IRiskAssessment, newValues: IRiskAssessment) {
  if (newValues.approvalFlows == null || original.approvalFlows == null) {
    return null;
  }
  return _.filter(
    getCurrentApprovalStages(newValues),
    (e) =>
      !_.isEqual(
        e,
        _.find(getCurrentApprovalStages(original), (oe) => oe.id === e.id),
      ),
  );
}

function getChangedDepartmentEvaluationSummaries(original: IRiskAssessment, newValues: IRiskAssessment) {
  if (newValues.evaluationSummary == null || original.evaluationSummary == null) {
    return null;
  }
  return _.filter(
    newValues.evaluationSummary.departmentSummaries,
    (e) =>
      !_.isEqual(
        e,
        _.find(original.evaluationSummary.departmentSummaries, (oe) => oe.department.id === e.department.id),
      ),
  );
}
