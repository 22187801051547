import React, { FC } from 'react';
import { Radio } from 'antd';
import { getIn, FormikProps } from 'formik';
import { IUserEditViewModel } from '../models/user';

import styles from './UserEditForm.module.css';
import { useTranslation } from 'react-i18next';

const PermissionSlider: FC<{ formik: FormikProps<IUserEditViewModel>; label: string; name: string; disabled?: boolean; onChange?: (value: boolean) => void }> = React.memo(({ formik, label, name, disabled, onChange }) => {
  const { values, setFieldValue } = formik;
  const [t] = useTranslation();
  let value = getIn(values, name) ?? false;
  
  const handleChange = (newValue: boolean) => {
      setFieldValue(name as any, newValue);
      if (onChange) {
        onChange(newValue);
      }
  };
  
  return (
    <div className={styles.permissionslider_container}>
      <Radio.Group value={value} buttonStyle="solid" style={{ display: 'flex', width: '6rem' }} size="small">
        <Radio.Button
          disabled={disabled}
          checked={value === false}
          onChange={() => handleChange(false)}
          style={{ flex: '1', textAlign: 'center', opacity: disabled ? 0.5 : 1 }}
          value={false}
        >
          {t('common.no')}
        </Radio.Button>
        <Radio.Button
          disabled={disabled}
          checked={value === true}
          onChange={() => handleChange(true)}
          style={{ flex: '1', textAlign: 'center', opacity: disabled ? 0.5 : 1 }}
          value={true}
        >
          {t('common.yes')}
        </Radio.Button>
      </Radio.Group>
      <span className={styles.permissionslider_label}>{label}</span>
    </div>
  );
});

PermissionSlider.displayName = 'PermissionSlider';

export default PermissionSlider;
