import {
  ApprovalStageOwner,
  DepartmentEvaluationOwner,
  DocumentTypes,
  IDocumentMetadata,
  RiskAssessmentOwnerTypes
} from '../../../models/documents/IDocumentMetaData';
import {UserInfo} from '../../../models/user/UserInfo';
import {RiskAssessmentStatus} from '../../../models/riskAssessment';
import {IPermissionCtx} from '../../riskAssessment/RiskAssessmentContext';

export interface IDocumentPermissionInfo {
  userInfo: UserInfo;
  metaData: IDocumentMetadata;
  state: RiskAssessmentStatus;
  context: IPermissionCtx;
}

const canRead = (info: IDocumentPermissionInfo) => isValidForRead(info);
const canCreate = (info: IDocumentPermissionInfo) => isValidForCreate(info);
const canEdit = (info: IDocumentPermissionInfo) => isValidForEdit(info) && info.metaData.isCheckedOut;
const canCheckIn = (info: IDocumentPermissionInfo) => isValidForEdit(info) && info.metaData.isCheckedOut && info.userInfo.subjectId === info.metaData.checkedOutBy;
const canCheckOut = (info: IDocumentPermissionInfo) => isValidForEdit(info) && !info.metaData.isCheckedOut && !info.metaData.isArchived;
const canArchive = (info: IDocumentPermissionInfo) => hasEditPermissionsAndNotClosed(info) && !info.metaData.isCheckedOut && !info.metaData.isArchived;
const canRevertCheckIn = (info: IDocumentPermissionInfo) =>
  isValidForEdit(info) && info.metaData.isCheckedOut && (info.userInfo.subjectId === info.metaData.checkedOutBy || info.context.permissionHelper.isProjectAdmin());
const canRevertArchive = (info: IDocumentPermissionInfo) => hasEditPermissionsAndNotClosed(info) && info.metaData.isArchived && info.userInfo.subjectId === info.metaData.archivedBy;
const canDelete = (info: IDocumentPermissionInfo) => isValidForEdit(info) && !info.metaData.isArchived && !info.metaData.isCheckedOut && info.context.permissionHelper.isProjectAdmin();

const isCheckInButtonVisible = (info: IDocumentPermissionInfo) => info.metaData.isCheckedOut;
const isCheckOutButtonVisible = (info: IDocumentPermissionInfo) => !info.metaData.isCheckedOut;
const isRevertCheckOutButtonVisible = (info: IDocumentPermissionInfo) => info.metaData.isCheckedOut;
const isArchiveButtonVisible = (info: IDocumentPermissionInfo) => !info.metaData.isArchived;
const isRevertArchiveButtonVisible = (info: IDocumentPermissionInfo) => info.metaData.isArchived;
const isDeleteButtonVisible = (info: IDocumentPermissionInfo) => !info.metaData.isArchived && !info.metaData.isCheckedOut && info.context.permissionHelper.isProjectAdmin();

const isValidForRead = (info: IDocumentPermissionInfo) => {
  if (info.context.permissionHelper.canReadAllDocuments()) {
    return true;
  }
  if (info.metaData.owner && info.metaData.owner.ownerType === RiskAssessmentOwnerTypes.DepartmentEvaluationOwner) {
    return true;
  }
  if (info.metaData.owner && info.metaData.owner.ownerType === RiskAssessmentOwnerTypes.ApprovalStageOwner) {
    const owner = info.metaData.owner as ApprovalStageOwner;
    return info.context.permissionHelper.hasApprovalRights(owner.approvalStageId);
  }
  if (info.metaData.owner?.ownerType === RiskAssessmentOwnerTypes.SalesAndApprovalOwner) {
    return info.context.permissionHelper.canReadClearanceDocuments();
  }
  if (info.metaData.owner?.ownerType === RiskAssessmentOwnerTypes.SafeOwner) {
    return info.context.permissionHelper.canEditSafeDocuments();
  }
  switch (info.metaData.documentType) {
    case DocumentTypes.OfferOrderContract:
      return info.context.permissionHelper.canEditContractDocuments();
    case DocumentTypes.Calculation:
      return info.context.permissionHelper.canEditPmCalcDocuments();
    case DocumentTypes.Miscellaneous:
      return true;
  }
};

const isValidForCreate = (info: IDocumentPermissionInfo) => hasEditPermissions(info);
const isValidForEdit = (info: IDocumentPermissionInfo) => !isLockedByParaphrased(info) && hasEditPermissionsAndNotClosed(info);
const hasEditPermissionsAndNotClosed = (info: IDocumentPermissionInfo) => hasEditPermissions(info) && !isClosed(info);

const hasEditPermissions = (info: IDocumentPermissionInfo): boolean => {
  if (info.metaData.owner?.ownerType === RiskAssessmentOwnerTypes.DepartmentEvaluationOwner) {
    const owner = info.metaData.owner as DepartmentEvaluationOwner;
    return info.context.permissionHelper.hasDepartmentRights(owner.departmentId) && info.state === RiskAssessmentStatus.Assessment;
  }
  if (info.metaData.owner?.ownerType === RiskAssessmentOwnerTypes.ApprovalStageOwner) {
    const owner = info.metaData.owner as ApprovalStageOwner;
    return info.context.permissionHelper.hasApprovalRights(owner.approvalStageId) && info.state === RiskAssessmentStatus.Approval;
  }
  if (info.metaData.owner?.ownerType === RiskAssessmentOwnerTypes.SalesAndApprovalOwner) {
    return info.context.permissionHelper.canEditClearanceDocuments();
  }
  if (info.metaData.owner?.ownerType === RiskAssessmentOwnerTypes.SafeOwner) {
    return info.context.permissionHelper.canEditSafeDocuments();
  }
  switch (info.metaData.documentType) {
    case DocumentTypes.OfferOrderContract:
      return info.context.permissionHelper.canEditContractDocuments();
    case DocumentTypes.Calculation:
      return (
        info.context.permissionHelper.canEditPmCalcDocuments() &&
        (info.state === RiskAssessmentStatus.Created || info.state === RiskAssessmentStatus.Assessment || info.state === RiskAssessmentStatus.Evaluation)
      );
    case DocumentTypes.Miscellaneous:
      return (
        info.context.permissionHelper.canEditAppendicesDocuments() &&
        (info.state === RiskAssessmentStatus.Created ||
          info.state === RiskAssessmentStatus.Assessment ||
          info.state === RiskAssessmentStatus.Evaluation ||
          info.state === RiskAssessmentStatus.Approved)
      );
  }
};

const isClosed = (info: IDocumentPermissionInfo): boolean => info.state === RiskAssessmentStatus.Approved || info.state === RiskAssessmentStatus.Rejected;
const isLockedByParaphrased = (info: IDocumentPermissionInfo) => info.metaData.isParaphrased && !info.metaData.isCheckedOut;

export default {
  canRead,
  canCreate,
  canEdit,
  canCheckIn,
  canCheckOut,
  canArchive,
  canRevertCheckIn,
  canRevertArchive,
  canDelete,
  isCheckInButtonVisible,
  isCheckOutButtonVisible,
  isRevertCheckOutButtonVisible,
  isArchiveButtonVisible,
  isRevertArchiveButtonVisible,
  isDeleteButtonVisible,
};
