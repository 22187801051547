import {IUserSetting} from '../masterdata';
import {ITenantInfo, IUserTenantInfo} from '../masterdata/ITenant';
import {IMultiLanguageString} from '../IMultiLanguageString';

export interface IUser {
  id: string;
  name: string;
  email: string;
  isActive: boolean;
  assignedTenants: string[];
}

export interface IUserClaim {
  type: string;
  value: string;
  issuer: string;
  key?: number;
}

export interface ICanDeleteResult {
  blockingTenants: string[];
  valid: {
    isValid: boolean;
  };
}

export interface IUserInfoResult {
  subjectId: string;
  name: string;
  availableTenants: IUserTenantInfo[];
  allTenants: ITenantInfo[];
  claims: IUserClaim[];
  userSetting: IUserSetting;
}
export interface IAvailableAzureUsersResult {
  users: IAzureUser[];
}
export interface IAzureUser {
  displayName: string;
  givenName: string;
  accountEnabled: boolean;
  companyName: string;
  department: string;
  country: string;
  jobTitle: string;
  officeLocation: string;
  mail: string;
  id: string;
  userPrincipalName: string;
  tenantId: string;
  tenantName: string;
}

export interface IAzureUserRef {
  name: string;
  id: string;
  user: IAzureUser;
}
export interface IUserSettingResult {
  userSetting: IUserSetting;
}
export interface ITenantRef {
  id: string;
  name: string;
}

export interface IUsersEditViewModel {
  users: IUserEditViewModel[];
  assignableTenants: ITenantRef[];
  assignableBranchesOfIndustry: IBranchOfIndustryRef[];
  assignableDepartments: IDepartmentRefWithTenantAndBranch[];
}

export interface IUserEditViewModel {
  id: string;
  name: string;
  email: string;
  isActive: boolean;
  isAppAdministrator: boolean;
  assignedTenants: ITenantConfiguration[];
  assignableTenants: ITenantRef[];
  providerSubjectId: string;
}

export interface IUserViewModelRef {
  id: string;
  name: string;
}

export interface IUserViewModelRefResult {
  users: IUserViewModelRef[];
}

export interface IUserAddViewModel {
  id: string;
  name: string;
  email: string;
}

export interface ITenantConfiguration extends ITenantRef {
  isTenantAdministrator: boolean;
  hasTenantReadOnlyAllPermissions: boolean;
  assignedBranchesOfIndustry: IBranchOfIndustryConfiguration[];
  accessFromDepartmentSync: boolean;
}

export interface IBranchOfIndustryConfiguration extends IBranchOfIndustryRef {
  canEditUsers: boolean;
  canEditConfig: boolean;
  isProjectAdmin: boolean;
  canPrintRiskAnalysisReport: boolean;
  canEditAppendicesDocuments: boolean;
  canEditSafeDocuments: boolean;
  canEditContractDocuments: boolean;
  canEditPmCalcDocuments: boolean;
  canReadAllDocuments: boolean;
  dontShowCalculation: boolean;
  dontShowCalculationMargin: boolean;
  dontShowClearanceDocuments: boolean;
  assignedDepartments: IDepartmentConfiguration[];
}

export interface IBranchOfIndustryPermissions {
  isProjectAdmin: boolean;
  canPrintRiskAnalysisReport: boolean;
  canEditAppendicesDocuments: boolean;
  canEditSafeDocuments: boolean;
  canEditContractDocuments: boolean;
  canEditPmCalcDocuments: boolean;
  canReadAllDocuments: boolean;
  canReadCalculation: boolean;
  canReadCalculationMargin: boolean;
}

export interface IDepartmentConfiguration {
  id: string;
  name: IMultiLanguageString;
  hasDepartmentRights: boolean;
  isEmailReceiver: boolean;
}

export interface IBranchOfIndustryRef {
  id: string;
  tenantId: string;
  name: IMultiLanguageString;
}

export interface IDepartmentRefWithTenantAndBranch {
  id: string;
  tenantId: string;
  branchOfIndustryId: string;
  name: IMultiLanguageString;
}

export function createBranchConfig(branchOfIndustryRef: IBranchOfIndustryRef): IBranchOfIndustryConfiguration {
  return {
    id: branchOfIndustryRef.id,
    name: branchOfIndustryRef.name,
    tenantId: branchOfIndustryRef.tenantId,
    canEditUsers: false,
    canEditConfig: false,
    isProjectAdmin: false,
    canPrintRiskAnalysisReport: false,
    canEditAppendicesDocuments: false,
    canEditContractDocuments: false,
    canEditPmCalcDocuments: false,
    canEditSafeDocuments: false,
    canReadAllDocuments: false,
    dontShowCalculation: false,
    dontShowCalculationMargin: false,
    dontShowClearanceDocuments: false,
    assignedDepartments: [],
  };
}

export function createTenantConfig(tenantRef: ITenantRef): ITenantConfiguration {
  return { id: tenantRef.id, name: tenantRef.name, isTenantAdministrator: false, hasTenantReadOnlyAllPermissions: false, assignedBranchesOfIndustry: [], accessFromDepartmentSync: false };
}

export function createDepartmentConfig(departmentRef: IDepartmentRefWithTenantAndBranch): IDepartmentConfiguration {
  return { id: departmentRef.id, name: departmentRef.name, isEmailReceiver: false, hasDepartmentRights: false };
}
