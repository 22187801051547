import { IRiskAssessment, RiskAssessmentStatus, getCurrentApprovalStages, IRiskAssessmentConfig, ApprovalTypes, IApprovalFlowStage } from '../../../models/riskAssessment';
import _ from 'lodash';
import { ApprovalStageInfo } from './TransitionActions';
import { AllowedReturnStages } from '../../../models/masterdata';

export function getAvailableStageTransitions(riskAssessment: IRiskAssessment, config: IRiskAssessmentConfig, activeStageId: string, readonly: boolean): ApprovalStageInfo {
  if (!isApprovalAvailable(riskAssessment)) {
    return null;
  }

  const stages = getCurrentApprovalStages(riskAssessment);

  const indexOfCurrent = _.findIndex(stages, (stage) => stage.id === riskAssessment.approvalFlows.currentStageId);
  const currentStage = stages[indexOfCurrent];
  const hasCurrentStage = currentStage != null;
  const isActiveStateTheCurrentState = currentStage && currentStage.id === activeStageId;

  if (hasCurrentStage && !isActiveStateTheCurrentState) {
    return null;
  }

  const canMoveToDifferentStage = !activeStageId || isActiveStateTheCurrentState;
  let info: ApprovalStageInfo = {
    canShareForCommunication: false,
    canMoveToNoProject: false,
    canMoveToDeleteProject: false,
    canMoveToApproved: false,
    canGoToEvaluation: !readonly && riskAssessment.status === RiskAssessmentStatus.Approval && isActiveStateTheCurrentState && !currentStage.isCompleted,
    canCompleteAnalysis: !readonly && isActiveStateTheCurrentState && currentStage.canFinishProcess && currentStage.isCompleted,
    canCompleteCurrentApprovalStage: !readonly && isActiveStateTheCurrentState && !currentStage.isCompleted,
    canReopenCurrentApprovalStage: !readonly && isActiveStateTheCurrentState && currentStage.isCompleted,
    currentStage: !readonly && currentStage,
    prevStage: !readonly && canMoveToDifferentStage && stages[indexOfCurrent - 1],
    nextStage: !readonly && canMoveToDifferentStage && (!hasCurrentStage || currentStage.isCompleted) && stages[indexOfCurrent + 1],
  };

  let flowTemplate = _.find(riskAssessment.processTemplate?.approvalFlows?.flows, (f) => f.id === riskAssessment.approvalFlows.currentFlowId);
  let currentStageTemplate = _.find(flowTemplate?.stages, (s) => s.id === currentStage.id);
  let approvalType = currentStageTemplate?.approvalType;
  let allowedReturnStages = config.allowedApprovalReturnStages ?? AllowedReturnStages.All;

  if (allowedReturnStages === AllowedReturnStages.All) {
    info.canMoveToPrevStage = info.prevStage != null;
  } else if (approvalType == null) {
    info.canMoveToPrevStage = false;
  } else {
    let matchesApprovalReturnStage = false;
    switch (approvalType) {
      case ApprovalTypes.BLV:
        matchesApprovalReturnStage = (allowedReturnStages & AllowedReturnStages.BLV) === AllowedReturnStages.BLV;
        break;
      case ApprovalTypes.GFBMS:
        matchesApprovalReturnStage = (allowedReturnStages & AllowedReturnStages.GFBMS) === AllowedReturnStages.GFBMS;
        break;
      case ApprovalTypes.GFBGG:
        matchesApprovalReturnStage = (allowedReturnStages & AllowedReturnStages.GFBGG) === AllowedReturnStages.GFBGG;
        break;
    }

    info.canMoveToPrevStage = info.prevStage && matchesApprovalReturnStage;
  }
  return info;
}

export function isApprovalAvailable(riskAssessment: IRiskAssessment) {
  const status = riskAssessment.status;
  return status === RiskAssessmentStatus.Approval || (status === RiskAssessmentStatus.Evaluation && _.isEmpty(riskAssessment.assessmentRequiredDepartmentsWithoutCompletion));
}
